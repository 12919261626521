.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-between; */
    justify-content: center;

    /* background-image: url("/public/images/modalTasksContent/base.webp"); */
    background-image: url("/public/images/channel_base.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    width: 95.28vw;
    aspect-ratio: 343 / 254;
    height: auto;

    gap: 16px;
}

.btnClose {
    position: absolute;
    top: -1.21vh;
    right: 0;
    z-index: 10;
}

.caseImg {
    position: absolute;
    top: -22vh;

    left: 50%;
    transform: translateX(-50%);

    width: 90vw;
    aspect-ratio: 360 / 320;
    height: auto;
}

.textTitle {
    margin-top: 10vh;

    text-transform: uppercase;
    font-size: 1.25rem;
    color: var(--header-text);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.textBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}

.text {
    text-transform: uppercase;
    font-size: 0.875rem;
    color: var(--header-text);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

@keyframes clickAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

.btn {
    position: absolute;
    bottom: -2vh;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: transparent;
    transition: transform 0.1s;
    background-image: url("/public/images/modalTasksContent/btn_blue.webp");

    width: 45.83vw;
    aspect-ratio: 165 / 40;
    height: auto;
    pointer-events: visible;
}

.btn:active {
    animation: clickAnimation 0.2s forwards;
}

@media screen and (min-height: 740px) {
    .caseImg {
        top: -18vh;
    }
}