.list {
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 0 4.44vw;
    width: 91.11vw;
    height: 15vh;
    overflow-y: auto;
    pointer-events: visible;
}


@media screen and (min-height: 570px) {
    .list {
        height: 17vh;
    }
}

@media screen and (min-height: 600px) {
    .list {
        height: 20vh;
    }
}

@media screen and (min-height: 650px) {
    .list {
        height: 24vh;
    }
}

@media screen and (min-height: 750px) {
    .list {
        height: 29vh;
    }
}

@media screen and (min-height: 850px) {
    .list {
        height: 35vh;
    }
}


.listItemBox {
    position: relative;
}

.listItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.89vh 5.9vw 0.89vh 2.9vw;

    background-image: url("/public/images/frens/layer.webp");
    /* background-size: cover; */
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;
}

.ropesBG {
    position: absolute;
    top: 70%;
    left: 50%;
    height: auto;
    z-index: -1;
    transform: translateX(-50%);

    width: 83.33vw;
    aspect-ratio: 300 / 48;
    height: auto;

    opacity: 1;
}

.friend {
    display: flex;
    align-items: center;
    gap: 8px;
}

.position {
    /* width: 24px;
    height: 24px; */
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    width: 5.58vw;
    aspect-ratio: 24 / 24;
    height: auto;
}

.goldPos {
    background: var(--gold)
}

.silverPos {
    background: var(--silver)
}

.bronzePos {
    background: var(--bronze)
}

.lowRatingPos {
    background: var(--lowRating)
}

.over100 {
    background: var(--lowRating);
    font-size: 0.8rem;
}

.over1000 {
    background: var(--lowRating);
    font-size: 0.6rem;
}

.over10000 {
    background: var(--lowRating);
    font-size: 0.5rem;
}

.avatarImg {
    /* width: 32px;
    height: 32px; */
    border-radius: 50%;
    border: 1px solid black;

    width: 8.52vw;
    aspect-ratio: 32 / 32;
    height: auto;
}

.friendName {
    text-transform: uppercase;
    font-size: 1rem;
    color: var(--header-text);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.score {
    display: flex;
    align-items: center;
    gap: 4px;
}

.scoreImg {
    width: 5.33vw;
    aspect-ratio: 20 / 20;
    height: auto;
}

.scoreText {
    text-transform: uppercase;
    font-size: 1rem;
    color: rgba(248, 158, 54, 1);
}