.barContainer {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: transparent;
    border-radius: 4px;
}

.barProgress {
    position: relative;
    background-image: url('/public/images/boost/progress_panel.webp');
    border: 1px solid rgba(33, 11, 2, 1);

    width: 29vw;
    min-height: 6vw;
    aspect-ratio: 120 / 16;
    height: auto;
}

.barComplete {
    background-image: url('/public/images/boost/progress_complete.webp');
    height: 2.42vh;
    min-height: 6vw;
    transition: width 0.5s ease-in-out;
}

.mainText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1rem;
    color: var(--white);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}