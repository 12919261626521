.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-image: url("/public/images/modal_base_light.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    width: 65.28vw;
    aspect-ratio: 235 / 169;
    height: auto;

    gap: 16px;
}

.btnClose {
    position: absolute;
    top: -2vh;
    right: -2vw;
}

.textTitle {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: var(--black);
}

.textBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: var(--nav-text-inactive);
    padding: 2px 4px;
    border-radius: 10px;
}

.text {
    text-transform: uppercase;
    font-size: 0.625rem;
    line-height: 0.75rem;
    color: var(--header-text);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.textBtn {
    text-transform: uppercase;
    font-size: 0.75rem;
    color: var(--header-text);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.textNote {
    text-transform: uppercase;
    font-size: 0.75rem;
    line-height: 1rem;
    color: var(--black);
    text-align: center;

    width: 45vw;
}


@keyframes clickAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

.btn {
    position: absolute;
    bottom: -2vh;

    background-image: url("/public/images/modalTasksContent/btn_blue.webp");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: transparent;
    transition: transform 0.1s;

    width: 30.28vw;
    aspect-ratio: 109 / 40;
    height: auto;
    pointer-events: visible;
}

.btn:active {
    animation: clickAnimation 0.2s forwards;
}