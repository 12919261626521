.list {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 4.44vw;
    width: 91.11vw;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: 7vh;
    pointer-events: visible;
}

@media screen and (min-height: 860px) {
    .list {
        padding-bottom: 0;
    }
}

.listItemBox {
    position: relative;
}

@keyframes fadeInFromTop {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.listItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 8px 24px 8px 12px; */
    padding: 0.89vh 5.9vw 0.89vh 2.9vw;

    background-image: url("/public/images/tasks/panel.webp");
    /* background-size: cover; */
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;

    opacity: 0;
    animation: fadeInFromTop 0.5s ease forwards;

}

.listItemDone {
    opacity: 0.5;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(70%);
        transform: translateX(-50%);
    }

    to {
        opacity: 1;
        transform: translateY(90%);
        transform: translateX(-50%);
    }
}

.ropesBG {
    position: absolute;
    top: 90%;
    left: 50%;
    z-index: -1;
    transform: translateX(-50%);
    transform: translateY(90%);

    width: 77.78vw;
    /* aspect-ratio: 280 / 72; */
    aspect-ratio: 280 / 20;
    height: auto;

    opacity: 0;
    animation: fadeIn 2.5s ease forwards;
}

.progressBarContainer {
    /* width: 43vw;
    aspect-ratio: 166 / 43;
    height: auto;
    padding-left: 4vw; */

    flex-grow: 1;
    /* display: flex; */
    /* flex-direction: column; */
    /* align-items: flex-start;
    justify-content: space-between; */
    padding-left: 4vw;
}

.rewardBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-image: url("/public/images/boost/reward_panel.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    width: 13.33vw;
    aspect-ratio: 48 / 48;
    height: auto;
}

.energyImg {
    width: 3.5vw;
    aspect-ratio: 14 / 24;
    height: auto;
}

.iconImg {
    width: 11.5vw;
    aspect-ratio: 24 / 24;
    height: auto;
}

.buyImg {
    /*width: 3.5vw;*/
    /*aspect-ratio: 14 / 24;*/
    height: auto;
}


.reward {
    font-size: 0.9rem;
    color: #F29911;
    /* color: linear-gradient(180deg, #FFEB13 1.03%, #F29911 99.67%); */
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 1px 0 #000,
        1px 1px 0 #000;
}

/* BTN ================= */

@keyframes clickAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}


.btn {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: transparent;
    transition: transform 0.1s;

    width: 22.78vw;
    aspect-ratio: 82 / 32;
    height: auto;
}

.btnClaim {
    background-image: url('/public/images/boost/boost_btn_claim.webp');
    pointer-events: visible;
}

.btnGo {
    background-image: url('/public/images/boost/boost_btn_go.webp');
    pointer-events: visible;
}

.btnClaim:active {
    animation: clickAnimation 0.2s forwards;
}

.btn:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.btnGo:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.mainText {
    text-transform: uppercase;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: var(--white);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.checkedImg {
    width: 8.89vw;
    aspect-ratio: 32 / 32;
    height: auto;
}

.opacity {
    opacity: 50%;

    position: absolute;
    z-index: 2;

    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("/public/images/tasks/panel.webp");

    width: 91.11vw;
    aspect-ratio: 328 / 64;
    height: auto;
}

.timer {
    font-size: 1.2rem;
    color: var(--white);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}


.starsBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: transparent;
    transition: transform 0.1s;
    pointer-events: visible;

    width: 22.5vw;
    aspect-ratio: 81 / 32;
    height: auto;
}

.starsBtn:active {
    animation: clickAnimation 0.2s forwards;
}

.btnStarImg {
    width: 6.67vw;
    aspect-ratio: 24 / 24;
    height: auto;
}

/* PURCHASE TAPS */
.purcaseTapsBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.innerBox {
    display: flex;
    flex-direction: column;
}

.tapsPrice {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.descrBase {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: transparent;
    border-radius: 4px;

    position: relative;
    background-image: url('/public/images/boost/progress_panel.webp');
    border: 1px solid rgba(33, 11, 2, 1);

    width: 29vw;
    aspect-ratio: 120 / 16;
    min-height: 6vw;
    /*height: auto;*/

    display: flex;
    align-items: center;
    justify-content: center;
}

.descrTextPurchase {
    text-align: center;
    width: 96%;
    text-transform: uppercase;
    font-size: 0.45rem;
    color: var(--header-text);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.descrTextBuy {
    text-align: center;
    width: 96%;
    text-transform: uppercase;
    font-size: 0.45rem;
    color: var(--header-text);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.starsBtnText {
    text-align: center;
    text-transform: uppercase;
    font-size: 1.25rem;
    line-height: 1.5rem;
    color: var(--white);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.saleImg {
    position: absolute;
    bottom: 0;

    width: 11.39vw;
    aspect-ratio: 41 / 12;
    height: auto;
}

.saleBase {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: transparent;
    position: absolute;
    bottom: 0;

    background-image: url('/public/images/boost/sale_base.webp');

    width: 12vw;
    aspect-ratio: 41 / 12;
    height: auto;

    display: flex;
    align-items: center;
    justify-content: center;
}

.littleStarImg {
    width: 4vw;
    aspect-ratio: 16 / 16;
    height: auto;
}

.saleText {
    text-transform: uppercase;
    font-size: 0.55rem;
    line-height: 0.625;
    color: var(--white);
    -webkit-text-stroke: 0.05em var(--dark-red);
    text-stroke: 0.05em var(--dark-red);
}

.oldPriceText {
    font-size: 0.75rem;
    color: var(--white);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
    /* Для работы псевдоэлемента */
    position: relative;
}

.oldPriceText::after {
    content: "";
    position: absolute;
    top: 40%;
    left: -30%;
    width: 150%;
    height: 1.5px;
    background-color: var(--black);
    transform: rotate(-10deg);
    transform-origin: center;
}