.list {
    display: flex;
    flex-direction: column;
    /* gap: 4px; */
    padding: 0 4.44vw;
    width: 91.11vw;
    overflow-y: auto;
    pointer-events: visible;
}

.listItemBox {
    position: relative;
}

@keyframes fadeInFromTop {
    from {
        opacity: 0;
        transform: translateY(-20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.listItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* padding: 8px 24px 8px 12px; */
    padding: 0.89vh 5.9vw 0.89vh 2.9vw;

    background-image: url("/public/images/tasks/panel.webp");
    /* background-size: cover; */
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    z-index: 1;

    opacity: 0;
    animation: fadeInFromTop 0.5s ease forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(70%);
        transform: translateX(-50%);
    }

    to {
        opacity: 1;
        transform: translateY(90%);
        transform: translateX(-50%);
    }
}

.ropesBG {
    position: absolute;
    top: 70%;
    /* left: 50%; */
    left: 7%;
    z-index: -1;
    transform: translateX(-50%);
    transform: translateY(-50%);

    width: 77.78vw;
    aspect-ratio: 280 / 72;
    height: auto;

    opacity: 0;
    animation: fadeInFromTop 2s ease forwards;
}

.opacity {
    opacity: 50%;

    position: absolute;
    z-index: 2;

    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("/public/images/tasks/panel.webp");

    width: 91.11vw;
    aspect-ratio: 328 / 64;
    height: auto;
}

.avaPanel {
    margin-left: 4vw;
    /* padding: 13px 8px 8px 8px; */
    /* padding: 1.97vh 2.22vw 1.21vh 2.22vw; */
    background-image: url("/public/images/tasks/ava_panel.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 13.33vw;
    aspect-ratio: 48 / 54;
    height: auto;
}

.baseImg {
    margin-top: 0.5vh;
    width: 8.52vw;
    aspect-ratio: 32 / 32;
    height: auto;
}

.textContainer {
    flex-grow: 1;
    padding-left: 4.44vw;
}

.taskText {
    text-transform: uppercase;
    /* font-size: 1rem; */
    font-size: 0.875rem;
    color: var(--header-text);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.rewardBox {
    display: flex;
    align-items: center;
    gap: 4px;
}

.coinImg {
    width: 5.56vw;
    aspect-ratio: 20 / 20;
    height: auto;
}

.checkedBox {
    margin-right: 4vw;
}

.checkedImg {
    width: 8.89vw;
    aspect-ratio: 32 / 32;
    height: auto;
}

/* GRADIENT */

.gradientBox {
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.textShadow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;

    text-align: center;
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--header-text);
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.textGradient {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;

    text-align: center;
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1.5rem;
    color: transparent;
    background: linear-gradient(to bottom, var(--gradient-top), var(--gradient-bot));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}