/* ============ closeBtn ======== */
.closeBtn {
    position: absolute;
    top: 10%;
    right: 10%;
}

/* ================= */

.container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    /* background: url("/public/images/modalQRContent/bg.webp") no-repeat center; */
    background-size: 100% 100%;

    width: 420px;
    height: 621px;
    /* width: 100vw;
    height: 100vh; */

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}

.sheepImg {
    position: absolute;
    top: -40%;
    width: 184px;
    height: 184px;

}

.qrBaseBox {
    position: relative;
    background: url("/public/images/modalQRContent/qr_base.webp") no-repeat center;
    background-size: 100% 100%;

    width: 315px;
    height: 336px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2vh;
}

.qrCodeImg {
    width: 226px;
    height: 208px;

}

.arrowImg {
    position: fixed;
    bottom: 15vh;

    width: 2.5vw;
    aspect-ratio: 20 / 50;
    height: auto;
}

.footer {
    position: fixed;
    bottom: 0;
    background: url("/public/images/modalQRContent/footer.webp") no-repeat center;
    background-size: 100% 100%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    /* width: 420px; */
    /* height: 118px; */
    height: 17.8vh;
    width: 100vw;
    gap: 2vh;
}

.baaText {
    font-size: 1.5rem;
}

.footerText {
    text-transform: uppercase;
    font-size: 1.5rem;
    color: var(--header-text);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

@media screen and (min-width: 1280px) {
    .container {
        width: 100vw;
        height: 100vh;
    }

    .sheepImg {
        position: absolute;
        top: -45%;

        width: 15vw;
        aspect-ratio: 184 / 184;
        height: auto;
    }

    .qrBaseBox {
        width: 22vw;
        aspect-ratio: 315 / 336;
        height: auto;
    }
}

@media screen and (min-width: 2560px) {
    .baaText {
        font-size: 3rem;
    }

    .footerText {
        font-size: 3rem;
        text-shadow: -2px -2px 0 #000,
            2px -2px 0 #000,
            0 4px 0 #000,
            2px 2px 0 #000;
    }
}