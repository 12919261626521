@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

.backdrop {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100dvh;
    background: rgba(24, 17, 9, 0.7);
    backdrop-filter: blur(10px);

    animation: fadeIn 500ms ease-in-out;
    pointer-events: auto;
}

.backdrop.fadeOut {
    animation: fadeOut 500ms ease-in-out forwards;
}