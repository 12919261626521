.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    background-image: url("/public/images/modalTasksContent/base.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    width: 95.28vw;
    aspect-ratio: 343 / 320;
    height: auto;

    gap: 16px;
}

.btnClose {
    position: absolute;
    top: -1.21vh;
    right: 0;
}

.sheepImgBox {
    position: absolute;
    top: -13vh;

    left: 50%;
    transform: translateX(-45%);

    background-image: url("/public/images/hot_offer_sheep.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    width: 57.22vw;
    aspect-ratio: 206 / 206;
    height: auto;
}

.titleOnBase {
    position: absolute;
    bottom: -5.5vh;

    left: 50%;
    transform: translateX(-55%);

    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    width: 45.83vw;
    aspect-ratio: 165 / 48;
    height: auto;
}


.btnEarnPosition {
    position: absolute;
    bottom: -2vh;

    left: 50%;
    transform: translateX(-50%);
}

.textBox {
    position: absolute;
    bottom: 20%;
    left: 50%;
    transform: translateX(-50%);

    width: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
}

/* Earn btn ========== */
@keyframes clickAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

.btnEarn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: transparent;
    transition: transform 0.1s;
    pointer-events: visible;

    width: 45.83vw;
    aspect-ratio: 165 / 40;
    height: auto;
}

.btnEarn:active {
    animation: clickAnimation 0.2s forwards;
}

.btnCoinImg {
    width: 5.56vw;
    margin-left: 1em;
    margin-right: 0.5em;
    aspect-ratio: 20 / 20;
    height: auto;
}

.hotOfferText {
    text-transform: uppercase;
    color: var(--header-text);
    font-size: 1.25rem;
    line-height: 1.5rem;
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.titleText {
    text-transform: uppercase;
    color: var(--header-text);
    font-size: 1.5rem;
    line-height: 2rem;
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.text {
    text-transform: uppercase;
    color: var(--header-text);
    font-size: 1rem;
    line-height: 1.5rem;
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

/* GRADIENT */

.gradientBox {
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.textShadow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;

    text-align: center;
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--header-text);
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.textGradient {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;

    text-align: center;
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1.5rem;
    color: transparent;
    background: linear-gradient(to bottom, var(--gradient-top), var(--gradient-bot));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}