.headerContainer {
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 550;
  width: 100vw;
}


/* ========== OTHER ============ */
.titleBox {
  position: relative;

  margin: 0 2.78vw;
  width: 60vw;
  aspect-ratio: 216 / 76;
  height: auto;
  pointer-events: auto;
}

.animate {
  animation: slideDown 0.5s ease-out forwards;
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

.woodenPlate {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  /* Нижний слой */
  width: 60vw;
  aspect-ratio: 216 / 76;
  height: auto;
}

.coinsBase {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  /* Средний слой */
  width: 53.33vw;
  aspect-ratio: 192 / 52;
  height: auto;
}

.paper {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  /* Средний слой */
  width: 44.44vw;
  aspect-ratio: 160 / 40;
  height: auto;
}

/* ========== GAME ============ */
.coin {
  position: absolute;
  top: 55%;
  left: 17.5%;
  transform: translate(-50%, -50%);
  z-index: 3;
  /* Верхний слой */
  width: 11.11vw;
  aspect-ratio: 1 / 1;
  height: auto;
}

.titleGame {
  position: absolute;
  top: 55%;
  left: 60%;
  transform: translate(-50%, -50%);
  z-index: 3;
  /* Верхний слой */
  color: var(--header-text);
  text-transform: uppercase;
  font-size: 1.875em;
}

.title {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  /* Верхний слой */
  color: var(--main-text);
  text-transform: uppercase;
  font-size: 1.875em;
}

/* ========== LEADER ============ */
.titleBoxLeader {
  position: relative;

  /* margin: 0 2.78vw; */
  width: 32.22vw;
  aspect-ratio: 116 / 40;
  height: auto;
}

.woodenPlateLeader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  /* Нижний слой */
  width: 32.22vw;
  aspect-ratio: 116 / 40;
  height: auto;
}

.paperLeader {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  /* Средний слой */
  width: 24.72vw;
  aspect-ratio: 89 / 22;
  height: auto;
}

.titleLeader {
  position: absolute;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 3;
  /* Верхний слой */
  color: var(--main-text);
  text-transform: uppercase;
  font-size: 1.25em;
}

.btnAbsolute {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 4vw;
}

.btnAbsoluteTask {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: -1vw;
}

.btnPlate {
  background-image: url("/public/images/header/yellow_btn_plate.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  display: flex;
  align-items: center;
  justify-content: center;

  /* padding-bottom: 0.5vh; */

  width: 12.22vw;
  aspect-ratio: 44 / 44;
  height: auto;
}

/* TASKS BTN FLICKER */

@keyframes flickerOpacity {
  0% {
    opacity: 0.25;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0.25;
  }
}

.flicker {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: -1;

  width: 25vw;
  aspect-ratio: 44 / 44;
  height: auto;

  animation: flickerOpacity 1s infinite;
}

/* LEADERS Boost Your Rank BTN */

@keyframes clickAnimation {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(0.9);
  }

  100% {
    transform: scale(1);
  }
}

.boostRankBtnPosition {
  /* transform использован для позиции и анимации, разделен что б не конфликтовал */
  position: absolute;
  bottom: -65vh;
  left: 50%;
  transform: translateX(-50%);
  z-index: 10;
}

.boostRankBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;

  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border: none;
  background-color: transparent;
  transition: transform 0.1s;
  pointer-events: visible;

  width: 55vw;
  aspect-ratio: 198 / 48;
  height: auto;

  box-shadow: 0 0 15px rgba(255, 223, 99, 0.7),
    0 0 25px rgba(255, 223, 99, 0.5);
}

.boostRankBtn:active {
  animation: clickAnimation 0.2s forwards;
}

.boostRankImg {
  width: 6.67vw;
  aspect-ratio: 24 / 24;
  height: auto;
}

.boostRankBtnText {
  text-transform: uppercase;
  color: var(--white);
  font-size: 1rem;
  text-shadow:
    -1px -1px 0 #000,
    1px -1px 0 #000,
    0 2px 0 #000,
    1px 1px 0 #000;
}