.scrollContainer {
    height: 85vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vh;
}

.titleOnBase {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 16px auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("/public/images/frens/frens_list_base.webp");
    /* width: 25.58vw; */
    width: 30vw;
    aspect-ratio: 110 / 32;
    height: auto;
    min-height: calc(25.58vw * (32 / 110));
}

.opacity {
    opacity: 50%;

    position: absolute;
    z-index: 2;

    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("/public/images/tasks/panel.webp");

    width: 91.11vw;
    aspect-ratio: 328 / 64;
    height: auto;
}

.text {
    text-transform: uppercase;
    color: var(--header-text);
    font-size: 0.875rem;
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

/* .stayTunedWrapper {
    background-color: var(--text-shadow3);
    padding: 2.42vh 0;
    min-width: 91.11vw;

    border-radius: 16px;
    display: flex;
    justify-content: center;
}

.stayTunedImg {
    width: 39.44vw;
    aspect-ratio: 142 / 208;
    height: auto;
} */

.stayTunedWrapper {
    position: relative;
    background-color: var(--text-shadow3);

    width: 91.11vw;
    aspect-ratio: 100 / 90;
    height: auto;

    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.stayTunedImgCloud {
    position: absolute;
    top: 4vh;
    right: 10vw;

    width: 28.89vw;
    aspect-ratio: 104 / 94;
    height: auto;
}

.stayTunedImgSheep {
    position: absolute;
    bottom: 4vh;
    transform: translateX(50%, -50%);

    width: 55vw;
    aspect-ratio: 122 / 117;
    height: auto;
}