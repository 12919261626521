.listItem {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;

    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;

    width: 91.11vw;
    height: auto;

    margin-bottom: 1vh;
}

.ropesBG {
    position: absolute;
    bottom: -1.35vh;
    left: 50%;
    height: auto;
    transform: translateX(-50%);

    width: 77.78vw;
    aspect-ratio: 280 / 10;
    height: auto;
}