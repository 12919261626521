.container {
    display: flex;
    align-items: center;
    flex-direction: column;

    width: 92vw;
    aspect-ratio: 340 / 20;
    height: auto;
}

.tapContainer {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-bottom: 4px;
}

@keyframes clickAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

.questionBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    /* gap: 4px; */

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: transparent;
    transition: transform 0.1s;
    pointer-events: visible;

    /* width: 28vw; */
    aspect-ratio: 77 / 12;
    height: auto;
}

.questionBtn:active {
    animation: clickAnimation 0.2s forwards;
}

.coinImg {
    width: 3.33vw;
    aspect-ratio: 12 / 12;
    height: auto;
}

.tapText {
    text-transform: uppercase;
    font-size: 0.625rem;
    color: var(--header-text);

    text-shadow:
        0.5px 0.5px 0.5px #000,
        /* Тень справа снизу */
        -0.5px 0.5px 0.5px #000,
        /* Тень слева снизу */
        -0.5px -0.5px 0.5px #000,
        /* Тень слева сверху */
        0.5px -0.5px 0.5px #000;
    /* Тень справа сверху */
}

.buttonsContainer {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90vw;
}

.buttonBox {
    /* position: relative; */

    width: fit-content;
    display: inline-flex;
    flex-direction: column;
    align-items: center;
}

.buttonBox img {
    position: absolute;
}

.absoluteLeft {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
}

.absoluteRight {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
}

.autotapTextImg {
    width: 14.72vw;
    aspect-ratio: 53.04 / 41.64;
    height: auto;

    bottom: -20%;
    /* bottom: -15%; */
    left: 0;
    right: -50%;
    margin: 0 auto;
}


.airdtopTextImg {
    width: 14.72vw;
    aspect-ratio: 51.54 / 34.52;
    height: auto;

    bottom: -20%;
    /* bottom: -15%; */
    left: -55%;
    right: 0;
    margin: 0 auto;
}

@media screen and (min-height: 660px) {
    .container {
        aspect-ratio: 340 / 88;
    }

    .autotapTextImg,
    .airdtopTextImg {
        bottom: -15%;
    }
}

.autotapCheck {
    width: 8.33vw;
    aspect-ratio: 30 / 30;
    height: auto;

    top: 65%;
    left: 0;
}

.airdtopCheck {
    width: 8.33vw;
    aspect-ratio: 30 / 30;
    height: auto;

    top: 65%;
    right: 0;
}

.timeBox {
    position: absolute;
    top: 80%;
    right: 30%;

    display: inline-block;
    padding: 2px 4px;
    background: var(--green-ligth-bg);
    border: solid 1px #000;
    border-bottom: solid 2px #000;
    font-size: 0.625rem;
    border-radius: 25px;
    color: var(--white);
    text-shadow:
        /* Черная обводка */
        -1px -1px 0 #000,
        1px -1px 0 #000,
        -1px 1px 0 #000,
        1px 1px 0 #000;
}