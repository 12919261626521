.titleBox {
    position: relative;

    margin: 0 2.78vw;
    width: 60vw;
    aspect-ratio: 216 / 76;
    height: auto;
    pointer-events: auto;
}

.title {
    text-align: center;
    position: absolute;
    width: 70%;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    /* Верхний слой */
    color: var(--main-text);
    text-transform: uppercase;
    /* font-size: 1.875em; */
}

.woodenPlate {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    /* Нижний слой */
    width: 60vw;
    aspect-ratio: 216 / 76;
    height: auto;
}

.coinsBase {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    /* Средний слой */
    width: 53.33vw;
    aspect-ratio: 192 / 52;
    height: auto;
}

.paper {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    /* Средний слой */
    width: 44.44vw;
    aspect-ratio: 160 / 40;
    height: auto;
}