.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    background: url("/public/images/bg_secondary.webp") no-repeat center;
    background-size: cover;

    height: 100vh;
    width: 100vw;
}

.btnClose {
    position: absolute;
    right: 5vw;
    top: 2.5vh;
    z-index: 3;
}

.chickenImg {
    width: 29.44vw;
    aspect-ratio: 106 / 97;
    height: auto;
}

/* ============= LIST ============= */

.listContainer {
    margin-top: 2vh;
    overflow-y: auto;
    overflow-x: hidden;
    /* height: 62vh; */
}

.listItemContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 16px;
    padding: 0 3vw;
}

@keyframes clickAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

.starsBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: transparent;
    transition: transform 0.1s;
    pointer-events: visible;

    width: 22.5vw;
    aspect-ratio: 81 / 32;
    height: auto;
}

.starsBtn:active {
    animation: clickAnimation 0.2s forwards;
}

.avaImg {
    width: 13.33vw;
    aspect-ratio: 48 / 54;
    height: auto;
}

.btnStarImg {
    width: 6.67vw;
    aspect-ratio: 24 / 24;
    height: auto;
}

.priceContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
}

.discountBox {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 4px;

    margin-left: 5.56vw;
}

.discountBox::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    transform: translateX(-100%);

    background-image: url("/public/images/autotap/ic_label.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    width: 5.56vw;
    aspect-ratio: 20 / 20;
    height: auto;
}

.text {
    text-transform: uppercase;
    font-size: 1rem;
    line-height: 1.25rem;
    color: var(--header-text);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}