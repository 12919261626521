.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    background: url("/public/images/bg_secondary.webp") no-repeat center;
    height: 100vh;
    width: 100vw;
}

.content {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    margin-top: 4vh;
    margin-bottom: 8vh;
}

.btnClose {
    position: absolute;
    right: 5vw;
    top: 2.5vh;
    z-index: 3;
}


.text {
    text-transform: uppercase;
    font-size: 1.25rem;
    color: var(--header-text);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.textLowercase {
    font-size: 0.55rem;
    color: var(--header-text);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.versionBox {
    position: absolute;
    color: var(--header-text);
    font-size: 0.7rem;
    bottom: 1vh;
}

/* ========= LIST ============= */
.list {
    display: flex;
    align-items: center;
    flex-direction: column;

    gap: 1.21vh;
    padding: 0 4.44vw;
    width: 91.11vw;
    overflow-y: auto;
}

.avaImg {
    margin-left: 7vw;
    width: 13.33vw;
    aspect-ratio: 48 / 54;
    height: auto;
}

.textContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    margin-left: 4.44vw;
    gap: 1.21vh;
    padding-right: 2.5vw;
}

/* ========= TOGGLE ============= */

.toggleBox {
    display: flex;
    align-items: center;
    margin-right: 7vw;

    background-image: url("/public/images/header/settings_switcher_bg.webp");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    border: none;

    width: 30vw;
    aspect-ratio: 108 / 36;
    height: auto;
}

.buttonBg {
    background-image: url("/public/images/header/settings_on.webp");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    border: none;

    align-content: center;

    width: 13.89vw;
    aspect-ratio: 50 / 32;
    height: auto;
}

/* ===== SAVE BTN */
@keyframes clickAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

.btnSave {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: transparent;
    transition: transform 0.1s;
    background-image: url("/public/images/modalTasksContent/btn_blue.webp");

    width: 45.83vw;
    aspect-ratio: 165 / 40;
    height: auto;
    pointer-events: visible;
}

.btnSubscribe:active {
    animation: clickAnimation 0.2s forwards;
}


.textBtn {
    text-transform: uppercase;
    font-size: 1rem;
    color: var(--header-text);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}