@keyframes clickAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

.btn {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: transparent;
    transition: transform 0.1s;

    -webkit-tap-highlight-color: transparent;

    aspect-ratio: 1 / 1;
    height: auto;
    pointer-events: visible;
}

.btn:active {
    animation: clickAnimation 0.2s forwards;
}