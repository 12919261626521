.container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    /* justify-content: flex-end; */
    align-items: center;

    width: 100vw;
    height: 100vh;
    /* gap: 5vh; */

    background-image: url("/public/images/keypad/shine.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    background-color: rgba(43, 30, 12, 1);
}

.btnClose {
    position: absolute;
    right: 5vw;
    top: 2.5vh;
    z-index: 3;
}

.mainTextBox {
    margin-top: 12vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6px;
}

.textSimple {
    text-transform: uppercase;
    font-size: 1rem;
    color: var(--header-text);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}



/* ======= KEYPAD =========== */
.keypadContainer {
    position: relative;
    /* width: 100%;
    height: 100%; */
    width: 100vw;
    aspect-ratio: 360 / 396;
    height: auto;
}

.keypad {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 3;


    display: flex;
    flex-direction: column;
    align-items: center;

    background-image: url("/public/images/keypad/base.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: transparent;

    width: 100vw;
    aspect-ratio: 360 / 396;
    height: auto;

    gap: 0.5vh;
}

.display {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 84.44vw;

    margin-top: 4vh;
}

.slot {
    display: flex;
    align-items: center;
    justify-content: center;

    text-transform: uppercase;
    font-size: 1.25rem;
    color: var(--header-text);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;


    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: transparent;

    width: 13.33vw;
    aspect-ratio: 46 / 48;
    height: auto;
}

.slot_default {
    background-image: url("/public/images/keypad/slot.webp");
}

.slot_blue {
    background-image: url("/public/images/keypad/slot_blue.webp");
}

.slot_red {
    background-image: url("/public/images/keypad/slot_red.webp");
}

.backspace {
    width: 5.55vw;
    aspect-ratio: 20 / 16;
    height: auto;
}

.buttons {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 8px;
}

@keyframes clickAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

.button {
    text-transform: uppercase;
    font-size: 1.25rem;
    color: var(--header-text);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;

    display: flex;
    align-items: center;
    justify-content: center;


    background-image: url("/public/images/keypad/unpressed.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: transparent;

    width: 26.66vw;
    aspect-ratio: 96 / 48;
    height: auto;

    transition: transform 0.1s;
    pointer-events: auto
}

.buttonEnter {
    text-transform: uppercase;
    font-size: 1.25rem;
    color: var(--header-text);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;


    background-image: url("/public/images/keypad/enter.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: transparent;

    width: 26.66vw;
    aspect-ratio: 96 / 48;
    height: auto;

    transition: transform 0.1s;
    pointer-events: auto
}

.buttonOpacity {
    opacity: 0.5;
}

.button:active {
    animation: clickAnimation 0.2s forwards;
    background-image: url("/public/images/keypad/pressed.webp");
}

.buttonEnter:active {
    animation: clickAnimation 0.2s forwards;
}

.divider {
    width: 84.44vw;
    aspect-ratio: 304 / 15;
    height: auto;
}


.safeImg {
    position: absolute;
    right: 0;
    top: -55%;
    z-index: 2;

    width: 90vw;
    aspect-ratio: 141 / 154;
    height: auto;
}

.sheepImg {
    position: absolute;
    left: 7vw;
    top: -27.5%;
    z-index: 6;

    width: 36.94vw;
    aspect-ratio: 133 / 123;
    height: auto;
}

/* ========= FOOTER ======= */
.footerImg {
    width: 6vw;
    aspect-ratio: 24 / 24;
    height: auto;
}

.footer {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 15;

    display: flex;
    align-items: center;
    justify-content: center;
    /* gap: 6vw;  */

    /* background-image: url("/public/images/tasks/panel.webp"); */
    background-image: url("/public/images/keypad/footer.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    width: 100vw;
    aspect-ratio: 360 / 70;
    height: auto;
}

.footerMargin {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 4vw;

    /* width: 84.44vw; */
}

.footerTitle {
    text-transform: uppercase;
    font-size: 0.75rem;
    color: var(--header-text);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.footerText {
    text-transform: uppercase;
    font-size: 1.25rem;
    color: var(--about-gold);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.footerBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 6px;
    width: 28.15vw;
}

.attemptsBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1vw;
}

/*===========*/
.titleBox {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    z-index: 10;

    /* margin: 0 2.78vw; */
    width: 60vw;
    aspect-ratio: 216 / 76;
    height: auto;
}

.woodenPlate {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    /* Нижний слой */
    width: 60vw;
    aspect-ratio: 216 / 76;
    height: auto;
}

.paper {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    /* Средний слой */
    width: 44.44vw;
    aspect-ratio: 160 / 40;
    height: auto;
}

.titleHeader {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    /* Верхний слой */
    color: var(--main-text);
    text-transform: uppercase;
    font-size: 1.25em;
}

/* ======== */