.container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    overflow-y: auto;
    overflow-x: hidden;

    width: 100vw;
    height: 100vh;
    gap: 16px;

    background-color: rgba(43, 30, 12, 1);
}

/* =========== */

.titleBox {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    z-index: 10;

    /* margin: 0 2.78vw; */
    width: 60vw;
    aspect-ratio: 216 / 76;
    height: auto;
}

.woodenPlate {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    /* Нижний слой */
    width: 60vw;
    aspect-ratio: 216 / 76;
    height: auto;
}

.paper {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    /* Средний слой */
    width: 44.44vw;
    aspect-ratio: 160 / 40;
    height: auto;
}

.titleHeader {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    /* Верхний слой */
    color: var(--main-text);
    text-transform: uppercase;
    font-size: 1.875em;
}

/* ======== */
.imageBox {
    position: relative;
    display: flex;
    flex-direction: column;

    margin-top: 6vh;
}

.noConnectionBg {
    width: 100vw;
    aspect-ratio: 360 / 396;
    height: auto;
}

.noConnectionWifi {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

    width: 26.66vw;
    aspect-ratio: 96 / 75;
    height: auto;
}

.textBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.textTitle {
    text-transform: uppercase;
    font-size: 1.25rem;
    color: var(--header-text);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.text {
    text-transform: uppercase;
    font-size: 1rem;
    color: var(--header-text);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

@keyframes clickAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

.btnRetry {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: transparent;
    transition: transform 0.1s;
    background-image: url("/public/images/modalTasksContent/btn_blue.webp");

    width: 45.83vw;
    aspect-ratio: 165 / 40;
    height: auto;
    pointer-events: visible;

    margin-bottom: 8vh;
}

.btnRetry:active {
    animation: clickAnimation 0.2s forwards;
}