.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    background: url("/public/images/bg_secondary.webp") no-repeat center;
    height: 100vh;
    width: 100vw;
}

.btnClose {
    position: absolute;
    right: 5vw;
    top: 2.5vh;
    z-index: 3;
}

.imgConfetti {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;

    width: 94.44vw;
    aspect-ratio: 340 / 315;
    height: auto;
}

.balanceBox {
    position: absolute;
    left: 4vw;
    top: 3.5vh;
    z-index: 3;

    height: 4.85vh;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.balanceInnerBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.balanceText {
    color: var(--white);
    font-size: 0.625rem;
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.balanceInnerText {
    color: var(--white);
    font-size: 0.875rem;
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.balanceStarImg {
    width: 4.44vw;
    aspect-ratio: 16 / 16;
    height: auto;
}

.starImg {
    width: 27.78vw;
    aspect-ratio: 100 / 100;
    height: auto;
}

.text {
    text-transform: uppercase;
    color: var(--white);
    font-size: 0.65rem;
    line-height: 1rem;
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.starBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 8px;
    width: 91.11vw;
    z-index: 1;
}

/* ============= LIST ============= */

.scrollContainer {

    overflow-y: auto;
    overflow-x: hidden;
    height: 62vh;
}

.listItemContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    gap: 16px;
    padding: 0 3vw;
}

@keyframes clickAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

.starsBtn {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: transparent;
    transition: transform 0.1s;
    pointer-events: visible;

    width: 25.56vw;
    aspect-ratio: 92 / 32;
    height: auto;
}

.starsBtn:active {
    animation: clickAnimation 0.2s forwards;
}

.avaImg {
    width: 8.89vw;
    aspect-ratio: 32 / 32;
    height: auto;
}

.btnStarImg {
    width: 6.67vw;
    aspect-ratio: 24 / 24;
    height: auto;
}

.textList {
    text-transform: uppercase;
    color: var(--white);
    font-size: 1rem;
    line-height: 1.5rem;
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.priceContainer {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-grow: 1;
}

.oldPriceBox {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.textOld {
    text-transform: uppercase;
    color: var(--white);
    font-size: 0.5rem;
    line-height: 0.75rem;
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.textOldPrice {
    color: var(--red);
    font-size: 1rem;
    /* line-height: 1.5rem; */
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}


/* ====== OLD PRICE LINE ====== */
.diagonalStrike {
    position: relative;
    display: inline-block;
}

.diagonalStrike::after {
    content: '';
    /* Пустое содержимое для псевдоэлемента */
    position: absolute;
    top: 50%;
    left: -15%;
    transform: translateY(-50%);
    width: 140%;
    height: 100%;
    transform: rotate(-10deg);
    /* Угол наклона */
    transform-origin: center;
    border-top: 1px solid black;
    /* Цвет и толщина диагональной линии */
    z-index: 2;
    pointer-events: none;
}

.diagonalStrike span {
    position: relative;
    z-index: 1;
    /* Текст поверх линии */
}