@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.pageContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1.4vh;
    /* gap: 2.4vh; */
    padding-top: 12.03vh;

    animation: fadeIn 500ms ease-in-out;
}