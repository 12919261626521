.pageContainer {
    position: relative;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
}

.tapImage {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    pointer-events: auto;

    margin-bottom: 2vh;
}

.riveComponent {
    width: 100%;
    height: 76%;
    padding-left: 2.42vw;
}

/* ========== SMALL SCREEN ========== */
@media screen and (min-height: 430px) and (max-width: 359px) {
    .tapImage {
        margin-bottom: 3vh;
    }

}

@media screen and (min-height: 450px) and (max-width: 359px) {
    .tapImage {
        margin-bottom: 4vh;
    }
}

@media screen and (min-height: 475px) and (max-width: 359px) {
    .tapImage {
        margin-bottom: 5vh;
    }
}

@media screen and (min-height: 500px) and (max-width: 359px) {
    .tapImage {
        margin-bottom: 7vh;
    }
}

/* ========== STANDART SCREEN ========== */
@media screen and (min-height: 550px) and (min-width: 360px) {
    .tapImage {
        margin-bottom: 3vh;
    }

    .riveComponent {
        height: 80%;
    }
}

@media screen and (min-height: 575px) and (min-width: 360px) {
    .tapImage {
        margin-bottom: 4vh;
    }
}

@media screen and (min-height: 600px) and (min-width: 360px) {
    .tapImage {
        margin-bottom: 5vh;
    }
}

@media screen and (min-height: 625px) and (min-width: 360px) {
    .tapImage {
        margin-bottom: 7vh;
    }
}

/* OLD ============================================== */
/* @media screen and (min-height: 400px) {
    .tapImage {
        margin-bottom: 2vh;
    }
}

@media screen and (min-height: 600px) {
    .tapImage {
        margin-bottom: 5vh;
    }
} */


.riveComponentContainer {
    display: flex;
    align-items: flex-start;
    justify-content: center;

    /* width: 100%;
    height: 100%; */

    width: 85vw;
    /* width: 100vw; */
    aspect-ratio: 221 / 330;
    height: auto;
    overflow: hidden;
    margin-bottom: 3vh;

    opacity: 0;
    transform: scale(0.95);
    transition: opacity 0.3s ease-in, transform 0.3s ease-in;

    /* Запретить выделение текста и действия по умолчанию */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
}

.riveChickContainer {
    position: absolute;
    z-index: 100;

    width: 30vw;
    aspect-ratio: 1/1;

    overflow: hidden;
    margin-bottom: 3vh;
    margin-right: 1vh;

    opacity: 0;
    transform: scale(0.95);
    transition: opacity 0.3s ease-in, transform 0.3s ease-in;

    /* Запретить выделение текста и действия по умолчанию */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
}

.riveChickContainer.visible {
    opacity: 1;
    transform: scale(1);
}

.riveComponentContainer.visible {
    opacity: 1;
    transform: scale(1);
}

@media screen and (min-height: 690px) {
    .riveComponentContainer {
        width: 100vw;
    }
}

.mainText {
    position: absolute;
    z-index: 5;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 1rem;
    color: var(--white);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.BackgroundImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
    /* Ensure it appears behind other content */
}

.ContentWrapper {
    position: relative;
    z-index: 1;
    /* Ensure this content is above the background image */
}

/* ================ FLYING +1 ===================== */

.containerFlying {
    position: absolute;
    /* position: relative; */
    width: 100vw;
    /* width: 100%; */
    height: 100%;
    overflow: hidden;
    /* background-color: aqua; */
}

.floatingFlying {
    position: absolute;
    font-size: 20px;
    font-weight: bold;
    animation: flyUp 1s ease-out forwards;
}

@keyframes flyUp {
    0% {
        transform: translateY(0);
        opacity: 0;
    }

    100% {
        transform: translateY(-50px);
        opacity: 1;
    }
}

/* GRADIENT */

.gradientBox {
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.textShadow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;

    text-align: center;
    text-transform: uppercase;
    font-size: 2rem;
    line-height: 2.5rem;
    color: var(--header-text);
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.textGradient {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;

    text-align: center;
    text-transform: uppercase;
    font-size: 2rem;
    line-height: 2.5rem;
    color: transparent;
    background: linear-gradient(to bottom, var(--gradient-top), var(--gradient-bot));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}