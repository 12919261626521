.pageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: 100vh;
    /* padding-top: 12.03vh; */
}

.toggleBtn {
    position: relative;

    display: flex;
    align-items: center;
    padding-left: 1.5vw;
    padding-right: 1.5vw;
    padding-bottom: 0.4vh;

    background-image: url("/public/images/leaders/leaders_toggle_bg.webp");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    border: none;

    width: 100vw;
    aspect-ratio: 360 / 48;
    height: auto;
}

.sheepImg {
    position: absolute;
    top: 6vh;
    left: 0;
    z-index: -1;
    width: 47.78vw;
    aspect-ratio: 172 / 166;
    height: auto;
}

.buttonBg {
    background-image: url("/public/images/leaders/leaders_toggle_btn.webp");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-color: transparent;
    border: none;

    align-content: center;

    width: 48.33vw;
    aspect-ratio: 174 / 32;
    height: auto;
}

.buttonNoBg {
    align-content: center;

    width: 48.33vw;
    aspect-ratio: 174 / 32;
    height: auto;
}

.toggleBtnText {
    text-align: center;
    text-transform: uppercase;
    color: var(--white);
    font-size: 1.25rem;
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.heroImg {
    margin-right: 4vw;
    align-self: flex-end;

    width: 54.17vw;
    aspect-ratio: 195 / 110;
    height: auto;
}

.headerContainer {
    position: relative;
    text-align: center;
}

.titleCounterBox {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.titleCounter {
    text-transform: uppercase;
    color: var(--header-text);
    font-size: 0.625rem;
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.textCounter {
    color: var(--white);
    font-size: 0.75rem;
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.titleOnBaseContainer {
    /* margin-top: 2.24vh; */
    padding: 0 4.44vw;
    /* width: 328px; */
    width: 91.11vw;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.titleOnBase {
    display: flex;
    align-items: center;
    justify-content: center;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("/public/images/frens/frens_list_base.webp");
    width: 25.58vw;
    aspect-ratio: 110 / 32;
    height: auto;
}

.text {
    text-transform: uppercase;
    color: var(--header-text);
    font-size: 0.875rem;
    line-height: 1rem;
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.titleOnBaseText {
    text-transform: uppercase;
    color: var(--white);
    font-size: 0.75rem;
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}