.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;

    background: url("/public/images/bg_secondary.webp") no-repeat center;
    background-size: cover;
    height: 100vh;
    width: 100vw;
}

.btnClose {
    position: absolute;
    right: 5vw;
    top: 2.5vh;
    z-index: 3;
}

.imgConfetti {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 0;

    width: 100vw;
    aspect-ratio: 360 / 206;
    height: auto;
}

.imgCup {
    width: 47.78vw;
    aspect-ratio: 172 / 114;
    height: auto;
}

.contentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    width: 91.11vw;
    gap: 10px;
}

.textBox {
    display: flex;
    flex-direction: column;

    gap: 4px;
}

.text15rem {
    font-size: 1.5rem;
    line-height: 1.5rem;
}

.text05rem {
    font-size: 0.5rem;
    line-height: 0.6rem;
}

.text {
    text-transform: uppercase;
    color: var(--header-text);
    font-size: 1rem;
    line-height: 1.5rem;
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.text065 {
    text-transform: uppercase;
    color: var(--header-text);
    font-size: 0.65rem;
    line-height: 0.75rem;
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.text06 {
    text-transform: uppercase;
    color: var(--header-text);
    font-size: 0.6rem;
    line-height: 0.6rem;
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.text055 {
    text-transform: uppercase;
    color: var(--header-text);
    font-size: 0.55rem;
    line-height: 0.6rem;
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.text04 {
    text-transform: uppercase;
    color: var(--header-text);
    font-size: 0.4rem;
    line-height: 0.5rem;
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

/* Table */

.table {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 4px;
    padding: 8px 0;

}

.cell {
    background-color: var(--white08);
    border-radius: 8px;
    padding: 4px 2px;
    gap: 4px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
}


/* GRADIENT */

.gradientBox {
    position: relative;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.textShadow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;

    text-align: center;
    text-transform: uppercase;
    /* font-size: 1rem;
    line-height: 1.5rem; */
    color: var(--header-text);
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.textGradient {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    z-index: 2;

    text-align: center;
    text-transform: uppercase;
    /* font-size: 1rem;
    line-height: 1.5rem; */
    color: transparent;
    background: linear-gradient(to bottom, var(--gradient-top), var(--gradient-bot));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}