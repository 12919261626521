/* BottomNavigation.module.css */

/* 1vw=360px * 1%=3.6px;
1vh=660px * 1%=6.6px */

.bottomNav {
  position: fixed;
  z-index: 500;
  bottom: 0;
  width: 100vw;
  /* height: auto; */
  height: 12.12vh;
  /* height: 80px; */
  display: flex;
  justify-content: space-around;
  background-image: url("/public/images/tabbar/Layer_6.webp");
  /* background-size: cover; */
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center;
  pointer-events: visible;
}

.navItem {
  position: relative;

  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  text-decoration: none;
  text-transform: uppercase;

  flex-direction: column;
  align-items: center;
  gap: 0.3125rem;
  padding-bottom: 0.625rem;
  -webkit-tap-highlight-color: transparent;
}

.itemName {
  text-transform: uppercase;
  color: var(--white);
  /* font-size: 16px; */
  font-size: 1rem;
  text-shadow:
    -1px -1px 0 #000,
    1px -1px 0 #000,
    0 2px 0 #000,
    2px 2px 0 #000;
}

.gradient {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 100%);
}

img {
  /* 32px=32 / 3.6=8.89vw;
   32px=32 / 6.6=4.85vh */
  width: 8.89vw;
  aspect-ratio: 32 / 32;
  height: auto;
  /* height: 4.85vh; */
  /* width: 32px;
  height: 32px; */

  transition: width 500ms, height 500ms;
}

.imgActive {
  width: 16.67vw;
  aspect-ratio: 60 / 60;
  height: auto;
  /* height: 9.09vh; */
  /* width: 60px;
  height: 60px; */
}

.badge {
  position: absolute;
  top: 1vh;
  right: 2vw;

  background-color: var(--orange);
  border-radius: 50%;

  width: 6.67vw;
  aspect-ratio: 24 / 24;
  height: auto;

  display: flex;
  justify-content: center;
  align-items: center;

  text-align: center;
  color: var(--white);
  font-size: 0.875rem;
  line-height: 1.25rem;
  text-shadow:
    -1px -1px 0 #000,
    1px -1px 0 #000,
    0 2px 0 #000,
    2px 2px 0 #000;

  transition: top 500ms, right 500ms;
}

.badgeActive {
  top: -2vh;
  right: 1vw;
}