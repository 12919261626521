/* TasksPage.module.css */

.scrollContainer {
    height: 85vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vh;
}

.titleOnBase {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("/public/images/frens/frens_list_base.webp");
    /* width: 25.58vw; */
    width: 30vw;
    aspect-ratio: 110 / 32;
    height: auto;
    min-height: calc(25.58vw * (32 / 110));
}

.text {
    text-transform: uppercase;
    color: var(--header-text);
    font-size: 0.875rem;
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.titleOnBaseText {
    text-transform: uppercase;
    color: var(--white);
    font-size: 0.75rem;
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.stayTunedWrapper {
    position: relative;
    background-color: var(--text-shadow3);

    width: 91.11vw;
    aspect-ratio: 100 / 90;
    height: auto;

    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
}


.stayTunedImgCloud {
    position: absolute;
    top: 4vh;
    right: 10vw;

    width: 28.89vw;
    aspect-ratio: 104 / 94;
    height: auto;
}

.stayTunedImgSheep {
    position: absolute;
    bottom: 4vh;
    transform: translateX(50%, -50%);

    width: 55vw;
    aspect-ratio: 122 / 117;
    height: auto;
}