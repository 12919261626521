/* качели */
@keyframes rotateAnimation {
    0% {
        transform: rotate(0deg);
    }

    5% {
        transform: rotate(0deg);
    }

    7.5% {
        transform: rotate(5deg);
    }

    10% {
        transform: rotate(-5deg);
    }

    12.5% {
        transform: rotate(5deg);
    }

    15% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

.barContainer {
    position: relative;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: transparent;

    background-image: url('/public/images/game_progress_panel_bot.webp');
    /* width: 45vw; */
    width: 60vw;
    aspect-ratio: 162 / 32;
    height: auto;
}

.barContainerAnimate {
    animation: rotateAnimation 11s ease-in-out infinite;
}

@keyframes clickAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

.btnBarSize {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: transparent;
    transition: transform 0.1s;
    pointer-events: visible;

    width: 60vw;
    aspect-ratio: 162 / 32;
    height: auto;
}

.btnBarSize:active {
    animation: clickAnimation 0.2s forwards;
}

.barComplete {
    position: absolute;
    top: 50%;
    left: 5%;
    transform: translate(0, -50%);

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: transparent;
    background-image: url('/public/images/boost/progress_complete.webp');
    /* height: 2.42vh; */
    height: 3vh;
    transition: width 0.5s ease-in-out;
}

.barPanelTop {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);

    /* width: 45vw; */
    width: 60vw;
    aspect-ratio: 162 / 31;
    height: auto;
}

.imgEnergy {
    position: absolute;
    z-index: 3;

    left: 7%;
    top: 50%;
    transform: translate(-50%, -50%);

    /* width: 5.28vw; */
    width: 7.28vw;
    aspect-ratio: 19 / 32;
    height: auto;
}

.imgScissors {
    position: absolute;
    z-index: 3;

    left: 5%;
    top: 50%;
    transform: translate(-50%, -50%);

    width: 13.83vw;
    aspect-ratio: 39 / 39;
    height: auto;
}

.barText {
    text-transform: uppercase;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 0.75rem;
    color: var(--white);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 1px 0 #000,
        1px 1px 0 #000;

}