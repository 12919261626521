.list {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    text-align: center;
    gap: 16px;
    padding: 13.03vh 4.44vw 15vh 4.44vw;
    width: 91.11vw;
    height: 80vh;
    overflow-y: auto;

    background: url("/public/images/bg_secondary.webp") no-repeat center;
    background-size: 100% 100%;
}

.listBox {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex-grow: 1;
}

.listItemBox {
    position: relative;
    text-align: start;
}

.listItem {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.89vh 5.9vw 0.89vh 2.9vw;

    background-image: url("/public/images/tasks/panel.webp");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center;
}

.ropesBG {
    position: absolute;
    top: 70%;
    left: 50%;
    z-index: -1;
    transform: translateX(-50%);

    width: 77.78vw;
    aspect-ratio: 280 / 72;
    height: auto;
}

.alarmImg {
    position: absolute;
    top: 50%;
    left: -3vw;
    z-index: 3;
    width: 8.89vw;
    aspect-ratio: 32 / 32;
    height: auto;
    transform: translateY(-50%);
}

.avaPanel {
    margin-left: 4vw;
    background-image: url("/public/images/tasks/ava_panel.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 13.33vw;
    aspect-ratio: 48 / 54;
    height: auto;
}

.baseImg {
    margin-top: 0.5vh;
    width: 8.52vw;
    aspect-ratio: 32 / 32;
    height: auto;
}

.textContainer {
    flex-grow: 1;
    padding-left: 4.44vw;
}

.pageTitleText {
    text-transform: uppercase;
    font-size: 1.25rem;
    color: var(--header-text);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.titleText {
    text-transform: uppercase;
    font-size: 1rem;
    color: var(--header-text);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.botText {
    text-transform: uppercase;
    font-size: 0.75rem;
    color: var(--header-text);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.textContinueBtn {
    text-transform: uppercase;
    font-size: 1.25rem;
    color: var(--header-text);
    text-shadow: 1px 1px 0 #000,
        1px 1px 0 #000,
        1px 2px 1px #000,
        1px 1px 0 #000;
}

/* ==== BUTTON =========== */
@keyframes clickAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

.continueBtn {
    margin: 0 auto;
    /* margin-top: auto; */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: transparent;
    background-image: url("/public/images/cheat_continue.webp");

    width: 55vw;
    aspect-ratio: 198 / 48;
    height: auto;

    transition: transform 0.1s;
}

.continueBtn:active {
    animation: clickAnimation 0.2s forwards;
}

/* ====== BUTTON ======== */
.titleBox {
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    z-index: 10;

    margin: 0 2.78vw;
    width: 60vw;
    aspect-ratio: 216 / 76;
    height: auto;
}

.woodenPlate {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    /* Нижний слой */
    width: 60vw;
    aspect-ratio: 216 / 76;
    height: auto;
}

.paper {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    /* Средний слой */
    width: 44.44vw;
    aspect-ratio: 160 / 40;
    height: auto;
}

.titleHeader {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    /* Верхний слой */
    color: var(--main-text);
    text-transform: uppercase;
    font-size: 1.875em;
}