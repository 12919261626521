.container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    background-image: url("/public/images/modalTasksContent/base.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    width: 95.28vw;
    aspect-ratio: 343 / 320;
    height: auto;
}

.btnClose {
    position: absolute;
    top: -1.21vh;
    right: 0;
}

.avaPanel {
    margin-top: 3.63vh;
    background-image: url("/public/images/tasks/ava_panel.webp");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    display: flex;
    align-items: center;
    justify-content: center;

    width: 19.72vw;
    aspect-ratio: 72 / 81;
    height: auto;
}

.baseImg {
    margin-top: 0.5vh;
    width: 13.33vw;
    aspect-ratio: 48 / 48;
    height: auto;
}

.textMain {
    font-size: 1.25rem;
    color: var(--header-text);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.rewardBox {
    display: flex;
    align-items: center;
    gap: 4px;
}

.coinImg {
    width: 8.89vw;
    aspect-ratio: 32 / 32;
    height: auto;
}

.reward {
    font-size: 1.25rem;
    color: #F29911;
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 1px 0 #000,
        1px 1px 0 #000;
}

@keyframes clickAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

.btnSubscribe {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: transparent;
    transition: transform 0.1s;
    background-image: url("/public/images/modalTasksContent/btn_blue.webp");

    width: 45.83vw;
    aspect-ratio: 165 / 40;
    height: auto;
    pointer-events: visible;
}

.btnSubscribe:active {
    animation: clickAnimation 0.2s forwards;
}

@keyframes shake {

    0%,
    100% {
        transform: translateX(0);
    }

    10% {
        transform: translateX(-3px);
    }

    20% {
        transform: translateX(3px);
    }

    30% {
        transform: translateX(-3px);
    }

    40% {
        transform: translateX(3px);
    }

    50% {
        transform: translateX(-3px);
    }

    60% {
        transform: translateX(3px);
    }

    70% {
        transform: translateX(-3px);
    }

    80% {
        transform: translateX(3px);
    }

    90% {
        transform: translateX(-3px);
    }
}

.btnCheck {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: transparent;
    transition: transform 0.1s;
    background-image: url("/public/images/modalTasksContent/btn_yellow.webp");

    width: 22.78vw;
    aspect-ratio: 82 / 32;
    height: auto;
    pointer-events: visible;
}

.btnCheck.shake {
    animation: shake 0.5s;
}

.textBtn {
    font-size: 1rem;
    color: var(--header-text);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.divider {
    width: 84.44vw;
    aspect-ratio: 304 / 15;
    height: auto;
}

.checkBox {
    margin-bottom: 3.63vh;

    display: flex;
    flex-direction: column;
    align-items: center;
}

.checkTextBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 46.39vw;
}

.textCheck {
    font-size: 0.75rem;
    color: var(--header-text);
    text-shadow: -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}