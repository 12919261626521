html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: 'Lilita One';
  src: url('./fonts/lilitaonerus.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

body {
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  font-family: "Lilita One";
  overflow: hidden;
  background: url("/public/images/bg_main.webp") no-repeat center;
  background-size: 100% 90%;
  background-position: top;
  pointer-events: none;
  -webkit-user-select: none;
}

body button,
body input {
  font-family: "Lilita One";
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.listHeight {
  height: 55vh;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

@media screen and (min-width: 1080px) {
  body {
    background-size: 100% 100%;
  }
}