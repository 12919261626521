/* TEST BTN */
.testCloseBtn {
    position: absolute;
    right: 5vw;
    top: 2.5vh;
    z-index: 3;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: var(--blue-sky-bg);
    height: 100vh;
}

.titleBox {
    margin-top: 4.85vh;
    display: flex;
    align-items: flex-start;
    flex-grow: 1;
    width: 100vw;
}

.progressBarBox {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--green-grass-bg);
    height: 13vh;
    width: 100vw;
}

.progressBarScale {
    scale: 1.2;
}

.sheepBox {
    background: url("/public/images/bg_wide.webp") no-repeat center;
    background-size: 100% 100%;

    width: 100vw;
    /* height: 35vh; */
    aspect-ratio: 372 / 248;
    height: auto;
    /* flex-grow: 1; */
    display: flex;
    align-items: center;
    justify-content: center;
}

.insideContainer,
.progressBarBox,
.sheepBox {
    padding: 0;
    margin: 0;
    border: none;
    box-sizing: border-box;
}

.heroImg {
    width: 100vw;
    aspect-ratio: 360 / 142;
    height: auto;
}

.sheepImg {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 61.11vw;
    aspect-ratio: 220 / 323;
    height: auto;
}