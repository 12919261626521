.baseBox {
    display: flex;
    align-items: center;
    gap: 8px;
    /* padding: 0 12px; */
    padding: 0 3.33vw;
}

@keyframes clickAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

.baseTabs {
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: transparent;

    display: flex;
    align-items: center;

    width: 100vw;
    aspect-ratio: 360 / 48;
    height: auto;
}

.tabsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.25vh;
    margin-left: 1.6vw;
    margin-right: 1.5vw;
}

.btnTabs {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: transparent;

    transition: transform 0.1s;
    pointer-events: visible;

    width: 24.17vw;
    aspect-ratio: 87 / 32;
    height: auto;
    cursor: pointer;
}

.btnTabs:active {
    animation: clickAnimation 0.2s forwards;
}

.textBox {
    width: 100%;
    text-align: center;
    padding-bottom: 0.6vh;
    /* margin-bottom: 0.6vh; */
}

.base {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 0 2.78vw;
    width: 40vw;
    aspect-ratio: 144 / 52;
    height: auto;
}

/* == ANIMATION START */
.right {
    transform: translateX(100%);
}

.left {
    transform: translateX(-100%);
}

.animateRight {
    animation: slideInFromRight 0.5s ease-out forwards;
}

.animateLeft {
    animation: slideInFromLeft 0.5s ease-out forwards;
}

@keyframes slideInFromRight {
    from {
        transform: translateX(100%);
    }

    to {
        transform: translateX(0);
    }
}

@keyframes slideInFromLeft {
    from {
        transform: translateX(-100%);
    }

    to {
        transform: translateX(0);
    }
}

/* == ANIMATION END */

.background-loaded {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.baseImg {
    width: 7.72vw;
    aspect-ratio: 32 / 32;
    height: auto;
}

.noFriendsWrapper {
    background-color: var(--text-shadow3);
    margin: 0 4.44vw;
    padding: 2.42vh 4.44vw;

    border-radius: 16px;
    display: flex;
    justify-content: flex-end;
}

.noFriendsImg {
    /* align-self: flex-end;
    margin-right: 14vw; */

    width: 60vw;
    aspect-ratio: 193 / 157;
    height: auto;
}

.btnTabsText {
    color: var(--white);
    font-size: 0.75rem;
    line-height: 1rem;
    text-transform: uppercase;
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.text {
    color: var(--header-text);
    font-size: 0.875rem;
    line-height: 1rem;
    text-transform: uppercase;
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}

.frensRotate {
    transform: rotate(3deg);
}

.earnedRotate {
    transform: rotate(-3deg);
}

.lowerText {
    font-size: 1.675rem;
    line-height: 2rem;
}

/* ====================================== */
.titleOnBase {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;

    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* width: 25.58vw; */
    width: 30vw;
    aspect-ratio: 110 / 32;
    height: auto;
    min-height: calc(25.58vw * (32 / 110));
}

/* ====================================== */

.referBase {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    /* width: 360px;
    height: 169px; */

    width: 100%;
    /* width: 83.72vw; */
    aspect-ratio: 360 / 169;
    height: auto;

    align-items: center;
    text-align: center;
    justify-content: center;


    position: fixed;
    bottom: 15%;
    left: 50%;
    transform: translateX(-50%);
}

.refBtnsBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    /* padding-top: 20px; */
    padding-top: 2.15vh;
}


@keyframes clickAnimation {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(0.9);
    }

    100% {
        transform: scale(1);
    }
}

.referalBtns {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border: none;
    background-color: transparent;
    transition: transform 0.1s;
    pointer-events: visible;
}

.inviteFriends {
    /* width: 198px;
    height: 48px; */

    width: 55vw;
    aspect-ratio: 198 / 48;
    height: auto;
}

.inviteFriendsTG:active,
.inviteFriends:active {
    animation: clickAnimation 0.2s forwards;
    /* Применение анимации при клике */
}

.refCoinBox {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.refCoinImg {
    /* width: 44px;
    height: 44px; */

    width: 10.23.72vw;
    aspect-ratio: 44 / 44;
    height: auto;
}

.textReferalEarn {
    color: var(--main-text);
    /* color: var(--white); */
    font-size: 40px;
}

.textReferalTop {
    padding-top: 20px;
    text-transform: uppercase;
    color: var(--main-text);
    font-size: 20px;
}

.textReferal {
    text-transform: uppercase;
    color: var(--main-text);
    font-size: 20px;
}

.textReferalBtn {
    text-transform: uppercase;
    color: var(--white);
    font-size: 20px;
    text-shadow:
        -1px -1px 0 #000,
        1px -1px 0 #000,
        0 2px 0 #000,
        1px 1px 0 #000;
}